import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

@NgModule({
  declarations: [FileUploaderComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    DropzoneModule,

  ],
  exports: [FileUploaderComponent]
})
export class FileUploadModule { }
