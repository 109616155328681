import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) { }

  // tslint:disable-next-line:max-func-body-length
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        },
      });
    }

    req = req.clone({ url: environment.apiUrl + req.url });

    return next.handle(req).pipe(catchError(this.handleError.bind(this)));
  }

  handleError(error: HttpErrorResponse) {
    let message = '';
    if (error.status === 401 && error.error === 'Insufficient permission') {
      // do not show an error message popup
      message = error.error;
    } else if (error.status === 401 && error.error === 'Demo organisation cannot be changed') {
      // do not show an error message popup
      message = error.error;
    } else if (error.status === 400) {
      // do not show an error message popup
      return throwError(error);
    } else if (error.status === 500) {
      message = 'Server error has occurred' + error.message;
    } else {
      message = error.message;
    }
    this.snackBar.open(message, 'close', { duration: 1000 });
    return throwError(error);
  }
}
