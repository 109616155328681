import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PageLayoutComponent } from '@layout/page-layout/page-layout.component';
import { AuthGuard } from '@core/auth.guard';


const routes: Routes = [{
  path: '',
  component: MainLayoutComponent,
  children: [{
    path: 'entries',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/entries/entries.module').then(m => m.EntriesModule)
  },
  { path: '', redirectTo: 'entries', pathMatch: 'full' }]
}, {
  path: '',
  component: PageLayoutComponent,
  children: [{
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  }, {
    path: 'form',
    loadChildren: () => import('./modules/entry-form/entry-form.module').then(m => m.EntryFormModule)
  }],
},
{ path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
