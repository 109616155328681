import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [],
  exports: [
    FlexLayoutModule
  ]
})
export class AngularFlexLayoutModule { }
