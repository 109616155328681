import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';


export interface APIRequest {
  [key: string]: unknown;
}

export interface APIResponse {
  [key: string]: unknown;
}

export interface FindByIdRequest extends APIRequest {
  idId: number;
}

export interface FindAllRequest<T> extends APIRequest {
  query?: {
    archived?: boolean,
    page?: number;
    pageSize?: number;
    // sort?: SortQuery<T>;
    // search?: SearchQuery<T>;
    // attributes?: ExtractByType<T>[];
  };
}

export interface FindAllResponse<T> extends APIResponse {
  rows: T[],
  count: number
}

// export interface PostRequest<T> extends APIRequest {
//   body: T
// }

export interface PutRequest<T> extends APIRequest {
  id: number,
  body: T
}

export interface PatchRequest<T> extends APIRequest {
  id: number,
  body: Partial<T>
}

export interface DeleteByIdRequest extends APIRequest {
  id: number
}


export type ApiRequest<RequestOptions extends APIRequest> =
  (options?: RequestOptions['request']) => Observable<RequestOptions['response']>;

export type PostRequest<RequestOptions extends APIRequest> =
  (options: RequestOptions['request']) => Observable<RequestOptions['response']>;

export type ParamsRequest<Params, RequestOptions, MethodResponse extends APIResponse> =
  (params: Params, options?: RequestOptions) => Observable<MethodResponse>;



export interface APITestRequest extends APIRequest {
  id: number;
  query?: {
    deleted?: boolean
  }
}

export interface APITestResponse extends APIResponse {
  id: number;
  query?: {
    deleted?: boolean
  }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PUT' | 'PATCH' | 'DELETE';

export abstract class ApiClientBase {
  public get<Request extends APIRequest>(
    url: string | string[],
  ): ApiRequest<Request> {
    return this.request.bind(this, 'GET', url);
  }

  public getWithParams<Request extends APIRequest, Response extends APIResponse>(
    url: string | string[],
  ): ParamsRequest<Request['params'], Request['request'], Response> {
    return this.request.bind(this, 'GET', url);
  }

  public post<Request extends APIRequest>(
    url: string | string[],
  ): PostRequest<Request> {
    return this.request.bind(this, 'POST', url);
  }

  public put<Request extends APIRequest, Response extends APIResponse>(
    url: string | string[],
  ): ParamsRequest<Request['params'], Request['request'], Response> {
    return this.request.bind(this, 'PUT', url);
  }

  public patch<Request extends APIRequest, Response extends APIResponse>(
    url: string | string[],
  ): ParamsRequest<Request['params'], Request['request'], Response> {
    return this.request.bind(this, 'PATCH', url);
  }

  public delete<Request extends APIRequest, Response extends APIResponse>(
    url: string | string[],
  ): ParamsRequest<Request['params'], Request['request'], Response> {
    return this.request.bind(this, 'DELETE', url);
  }

  public request(method: HttpMethod, url: string | string[], options?: APIRequest): Observable<APIResponse> {
    const parsedUrl = this.parseRouteParams(url, options);
    const params = this.getParams(options);
    const body = this.getBody(options);
    return this.sendRequest(method, parsedUrl, body, params);
  }

  private getParams(options: APIRequest) {
    let params = new HttpParams();
    if (options && options.query) {
      params = this.toHttpParams(options.query);
    }
    return params;
  }

  private getBody(options: APIRequest) {
    let body;
    if (options && options.body) {
      body = options.body;
    }
    return body;
  }

  abstract sendRequest(method: HttpMethod, url: string, body?: any, params?: HttpParams)

  private parseRouteParams(method: string | string[], options: APIRequest) {
    let url = '';

    if (Array.isArray(method)) {
      url = method.join('/');

      for (const route of method) {
        if (route.includes(':')) {
          const routeParam = route.substring(1);

          const param = options[routeParam] as string;

          if (param === null) {
            throw new Error(`Unable to find value for ${route}`);
          }

          url = url.replace(route, param);
        }
      }
    }
    else {
      url = method;
    }
    return url;
  }
  private toHttpParams(query?: { [key: string]: any }): HttpParams {
    let target: HttpParams = new HttpParams();
    if (query) {
      target = this.parseKeysToJSON(query, target);
    }
    return target;
  }

  private parseKeysToJSON(query: { [key: string]: any; }, target: HttpParams) {
    Object.keys(query).forEach((key: string) => {
      let value: any = query[key];
      if (value === '' || value === undefined) {
        return;
      }
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      else {
        value = value.toString();
      }
      target = target.append(key, value);
    });
    return target;
  }
}