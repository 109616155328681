import { NgModule } from '@angular/core';
import { PipesModule } from './pipes';
import { DirectivesModule } from './directives';
import { ComponentsModule } from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularFlexLayoutModule } from '@libs/layout/angular-flex/angular-flex-layout.module';
import { AngularMaterialModule } from '@libs/component/angular-material/angular-material.module';
import { AngularCdkModule } from '@libs/cdk/angular-cdk/angular-cdk.module';
import { TableModule } from './components/table/table.module';
import { FileUploadModule } from './components/file-uploader/file-uploader.module';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DropzoneModule,
  ],
  exports: [
    CommonModule,
    ComponentsModule,
    AngularFlexLayoutModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    AngularCdkModule,
    FileUploadModule,
    TableModule,
  ]
})
export class SharedModule { }
