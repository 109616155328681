import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from './table/table.module';
import { FileUploadModule } from './file-uploader/file-uploader.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [TableModule, FileUploadModule]
})
export class ComponentsModule { }
