import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiClientBase, HttpMethod, APIResponse, APIRequest } from './api.types';
import { Router } from '@angular/router';

import {
  LoginRoute, RegisterRoute, IdAuthRoute
} from '@common/api/auth';

import { EntryListRoute, EntryCreateRoute, EntryUpdateRoute, EntryGetRoute, EntryDeleteRoute, EntrySignRoute } from '@common/api/entries';
import { QuestionListRoute, QuestionSectionRoute } from '@common/api/questions';
import { FileUploadCreateRoute } from '@common/api/file-upload';
import { AnswerDataRoute, AnswersCreateRoute, AnswersRoute, EntryAnswersRoute } from '@common/api/answers';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService extends ApiClientBase {

  constructor(public http: HttpClient, private router: Router) {
    super();
  }

  private readonly authRoute = 'v1/auth';
  readonly auth = {
    login: this.post<LoginRoute>([this.authRoute, 'login']),
    entry: this.post<IdAuthRoute>([this.authRoute, 'entry']),
    register: this.post<RegisterRoute>([this.authRoute, 'register']),
    logout: () => {localStorage.clear(); this.router.navigateByUrl('/login'); },
  };

  private readonly entryRoute = 'v1/entries';
  readonly entries = {
    list: this.get<EntryListRoute>([this.entryRoute]),
    create: this.post<EntryCreateRoute>([this.entryRoute, '']),
    finish: this.get<EntryUpdateRoute>([this.entryRoute + '/finish', ':id']),
    update: this.get<EntryUpdateRoute>([this.entryRoute + '/update', ':id']),
    status: this.get<EntryUpdateRoute>([this.entryRoute + '/statusupdate', ':id']),
    get: this.get<EntryGetRoute>([this.entryRoute, ':id']),
    email: this.get<EntryGetRoute>([this.entryRoute + '/sendEmail', ':id']),
    bulkEmail: this.get<EntryListRoute>([this.entryRoute + '/sendBulk']),
    delete: this.delete<EntryDeleteRoute, APIRequest>([this.entryRoute + '/delete', ':id']),
    uuidExsists: this.get<EntryGetRoute>([this.entryRoute + '/UUID_exsits', ':id']),
    sign: this.post<EntrySignRoute>([this.entryRoute, '/sign'])
  };

  private readonly formRoute = 'v1/form';
  readonly questions = {
    list: this.get<QuestionListRoute>([this.formRoute + '/questionAll']),
    section: this.get<QuestionSectionRoute>([this.formRoute + '/questionSection', ':id']),
  };

  private readonly fileUploadRoute = 'v1/fileUpload';
  readonly fileUpload = {
    upload: this.post<FileUploadCreateRoute>([this.fileUploadRoute + '']),
  };

  private readonly answersRoute = 'v1/answers';
  readonly answers = {
    create: this.post<AnswersCreateRoute>([this.answersRoute, '']),
    update: this.post<AnswersCreateRoute>([this.answersRoute, ':id']),
    answers: this.get<EntryAnswersRoute>([this.answersRoute + '/entry', ':id']),
  };

  private readonly answerPublicRoute = 'v1/publicanswers';
  readonly publicanswers = {
    create: this.post<AnswerDataRoute>([this.answerPublicRoute, '']),
    answers: this.post<AnswersRoute>([this.answerPublicRoute + '/entry']),
  };

  sendRequest(method: HttpMethod, url: string, body?: any, params?: HttpParams) {
    switch (method) {
      case 'GET':
        return this.http.get<APIResponse>(url, { params });
      case 'POST':
        return this.http.post<APIResponse>(url, body, { params });
      case 'PUT':
        return this.http.put<APIResponse>(url, body, { params });
      case 'PATCH':
        return this.http.patch<APIResponse>(url, body, { params });
      case 'DELETE':
        return this.http.delete<APIResponse>(url, { params });
    }
  }
}
